//
// Measurements
//

// Sidebar Properties
$sidebar-width: 60px;
$sidebar-height: 100%;

// Container Properties
$container-width: calc(100% - #{$sidebar-width});
$container-height: 100%;

// -----------------------------------------

//
// Visual
//
$border-color: rgba(0, 0, 0, 0.15);

// Colors - Navigation
$color-navigation: #000;
$color-navigation-icon: rgba(255, 255, 255, 0.7);

// Colors - Light
$color-primary: #fff;
$color-secondary: none;
$color-inverse: #000;
$color-inverse-secondary: rgba(0,0,0,.7);
$color-text-accent: rgba(0, 0, 0, 0.7);
$color-accent: #03afff;
$color-overlay: rgba(0, 0, 0, 0.5);

// Colors - Dark
$dark-color-primary: #000;
$dark-color-inverse: #fff;
$dark-color-secondary: #1d1d1d; //rgba(29, 29, 29, 0.7);
$dark-color-text-accent: rgba(255, 255, 255, 0.7);
$dark-color-accent: #03afff;

// Breakpoint
$breakpoint: 769px;