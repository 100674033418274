@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import "src/variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*, ::after, ::before {
  box-sizing: border-box;
}

html, body {  
  height:100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: $color-secondary;
  color: $color-primary;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2, h3,
  h4, h5, h6 {
    color: $color-inverse;
  }

  a, p, li {
    color: $color-inverse;
  }

  a:hover {
    color: $color-accent;
  }
}

html.dark-mode, body.dark-mode {
  background-color: $dark-color-secondary;
  color: $dark-color-primary;  

  h1, h2, h3,
  h4, h5, h6 {
    color: #fff;
  }

  a, p, li {
    color: #fff;
  }

  a {
    text-decoration: none;
    border-bottom: 1px dotted $dark-color-text-accent;
  }
}

h1, h2, h3,
h4, h5, h6 {
  margin: 2rem 0 1rem;
  font-family: 'Roboto', 'Roboto Condensed', Arial, Helvetica, sans-serif;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

ul, ol {
  margin: 0 0 1rem;
}

li {
  font-family: 'Roboto', 'Roboto Condensed', Arial, Helvetica, sans-serif;
}

a {
  text-decoration: none;
}

p {
  margin: 0 0 1rem;
  line-height: 1.7;
}