@import 'src/variables.scss';

.overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0;
    position: fixed;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
    background-color: $color-overlay;

    &.show {
        opacity: 1;
        visibility: visible;
    }
}
