.expanded {
    display: block;
}

.collapsed {
    display: none;
}

.collapsing {
    overflow: hidden;
}
